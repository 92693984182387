<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <div class="text-center">
                            <h5 class="card-title"><i class="ri-edit-line"></i> {{ $i18n.locale === 'en' ? 'Trainer Profile' : 'প্রশিক্ষক প্রোফাইল' }}</h5>
                        </div>
                    </template>
                    <template v-slot:headerAction>
                        <b-button :disabled="overlay ? 'true' : false" class="mr-1" title="View Details" v-b-modal.modal-5 variant="primary" size="sm"><i class="ri-eye-line m-0 "></i> {{ $t('globalTrans.details') }}</b-button>
                    </template>
                    <template v-slot:body>
                        <b-row v-if="overlay" variant="default" show>
                            <b-col>
                                <h4 class="text-center mt-2 mb-4 text-info"><i class="ri-alert-fill mr-2"></i>{{ $t('externalTraining.dashboard_message') }}</h4>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <p><b>{{ $t('elearning_iabm.registration_note_2')}} (<span class="text-danger">*</span>) {{ $t('elearning_iabm.registration_note_3')}}</b></p>
                            </b-col>
                        </b-row>
                        <b-overlay :show="loading">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form id="form" @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                    <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('globalTrans.personal_information')}}</legend>
                                        <b-row class="mb-3 ml-2 mr-2">
                                            <b-col sm="4">
                                                <ValidationProvider name="Name" vid='name' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="name"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('user.name')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            id="name"
                                                            v-model="profileInfo.name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4">
                                                <ValidationProvider name="Name (bn)" vid='name_bn' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="name_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('user.name_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            id="name_bn"
                                                            v-model="profileInfo.name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="4" sm="4" v-show="nonMandatory">
                                                <ValidationProvider>
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="father_name"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('externalUserIrrigation.father_name')}} {{$t('globalTrans.enn')}}
                                                    </template>
                                                        <b-form-input
                                                            id="father_name"
                                                            v-model="profileInfo.name_of_father_husband"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="4" sm="4" v-show="nonMandatory">
                                                <ValidationProvider>
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="father_name"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('externalUserIrrigation.father_name_bn')}}
                                                    </template>
                                                        <b-form-input
                                                            id="father_name_bn"
                                                            v-model="profileInfo.name_of_father_husband_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="4" sm="4" v-show="nonMandatory">
                                                <ValidationProvider>
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="mother_name"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('globalTrans.mother_name_en')}}
                                                    </template>
                                                        <b-form-input
                                                            id="mother_name"
                                                            v-model="profileInfo.mother_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="4" sm="4" v-show="nonMandatory">
                                                <ValidationProvider>
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="mother_name_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('externalUserIrrigation.mother_name_bn')}}
                                                    </template>
                                                        <b-form-input
                                                            id="father_name"
                                                            v-model="profileInfo.mother_name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="nonMandatory">
                                                <ValidationProvider name="NID" vid='nid' :rules="'max:'+(invalidNid ? 0 : (10|13|17))+'|min:10|numeric'">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="nid"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalUserIrrigation.nid')}}
                                                        </template>
                                                        <b-form-input
                                                            id="nid"
                                                            v-model="profileInfo.nid"
                                                            @input="nidRules($event)"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="4" sm="12" v-show="nonMandatory">
                                                <ValidationProvider name="NID Attach" vid="nid_attachment" rules="">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    :label="$t('elearning_iabm.nid_attach')"
                                                    label-for="nid_attachment"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{$t('elearning_iabm.nid_attach')}}
                                                    </template>
                                                    <b-form-file
                                                        id="nid_attachment"
                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                        v-model="profileInfo.nid_file"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                    <a v-if="profileInfo.nid_attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/iab/personal_info/nid/' + profileInfo.nid_attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                                    <p class="text-danger mt-2" style="font-size: 10px;">{{$t('elearning_iabm.training_file_validation_format')}}</p>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="nonMandatory">
                                                <ValidationProvider name="Date Of Birth" vid="date_of_birth" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="date_of_birth"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('externalUserIrrigation.date_of_birth')}}
                                                    </template>
                                                    <b-form-input
                                                    class="fromDate"
                                                    v-model="profileInfo.date_of_birth"
                                                    :placeholder="$t('globalTrans.select_date')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4">
                                                <ValidationProvider name="Mobile" vid='mobile' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="mobile"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalUserData.mobile_no')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            id="mobile"
                                                            v-model="profileInfo.mobile"
                                                            disabled
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4">
                                                <ValidationProvider name="Email" vid='email' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="email"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalTraining.email')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            id="email"
                                                            v-model="profileInfo.email"
                                                            disabled
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4">
                                                <ValidationProvider name="Gender" vid="gender" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="gender"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.gender')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="profileInfo.gender"
                                                            :disabled="checkDisable('gender')"
                                                            :options="genderList"
                                                            id="gender"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="nonMandatory">
                                                <ValidationProvider name="Marital Status" vid="marital_status" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="marital_status"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalTraining.mat_status')}}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="profileInfo.marital_status"
                                                            :options="maritalList"
                                                            id="marital_status"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="nonMandatory">
                                                <ValidationProvider name="Blood Group" vid="blood_group" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="blood_group"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalTraining.blood_group')}}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="profileInfo.blood_group"
                                                            :options="bloodGroupList"
                                                            id="blood_group"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="nonMandatory">
                                                <ValidationProvider name="Religion" vid="religion" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="religion"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('externalTraining.religion')}}
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="profileInfo.religion"
                                                            :options="religionList"
                                                            id="religion"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="nonMandatory">
                                                <ValidationProvider name="Photo" vid="image">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        :label="$t('globalTrans.photo')"
                                                        label-for="image"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('globalTrans.photo')}}
                                                        </template>
                                                        <b-form-file
                                                            id="image"
                                                            accept="image/*"
                                                            v-model="profileInfo.image_file"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-file>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                        <a v-if="profileInfo.image" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/' + profileInfo.image" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                                        <p class="text-danger mt-2" style="font-size: 10px;">{{$t('elearning_iabm.training_image_validation_format')}}</p>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="4" sm="12" v-show="nonMandatory">
                                                <ValidationProvider name="Signature" vid="signature">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        :label="$t('globalTrans.signature')"
                                                        label-for="signature"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('globalTrans.signature')}}
                                                        </template>
                                                        <b-form-file
                                                            id="signature"
                                                            accept="image/*"
                                                            v-model="profileInfo.signature_file"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-file>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                        <a v-if="profileInfo.signature" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/' + profileInfo.signature" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                                        <p class="text-danger mt-2" style="font-size: 10px;">{{$t('elearning_iabm.training_image_validation_format')}}</p>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </fieldset>
                                    <fieldset class="p-2 w-100" v-show="nonMandatory">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.emergency_contact_information')}}</legend>
                                        <b-row class="mb-3 ml-2 mr-2">
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Emergency Contact Name" vid='emergency_name'>
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="emergency_name"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.emergency_contact_name')}}  {{$t('globalTrans.enn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="emergency_name"
                                                        v-model="profileInfo.emergency_name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Emergency Contact Name Bn" vid='emergency_name_bn'>
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="emergency_name"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.emergency_contact_name')}}  {{$t('globalTrans.bnn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="emergency_name"
                                                        v-model="profileInfo.emergency_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Relation" vid='emergency_relation'>
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="emergency_relation"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.emergency_relation')}}  {{$t('globalTrans.enn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="emergency_relation"
                                                        v-model="profileInfo.emergency_relation"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Relation Bn" vid='emergency_relation_bn'>
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="emergency_relation"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.emergency_relation')}}  {{$t('globalTrans.bnn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="emergency_relation"
                                                        v-model="profileInfo.emergency_relation_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6">
                                                <ValidationProvider name="Emergency Contact" vid='emergency_contact' rules="min:11|max:11">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="emergency_contact"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('elearning_iabm.emergency_contact_number')}}
                                                    </template>
                                                    <b-form-input
                                                        id="emergency_contact"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        v-model="profileInfo.emergency_contact"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </fieldset>
                                    <fieldset class="p-2 w-100" v-show="nonMandatory">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.present_address')}}</legend>
                                        <b-row class="mb-3 ml-2 mr-2">
                                            <b-col sm="4">
                                                <ValidationProvider name="Area Type" vid="pre_area_type_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="area_type_id "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        :disabled="checkDisable('pre_area_type_id', 'address')"
                                                        v-model="profileInfo.address.pre_area_type_id"
                                                        id="area_type_id"
                                                        :options="getAreaTypeList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="ItemShow">
                                                <ValidationProvider name="Division" vid="pre_division_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="pre_division_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.pre_division_id"
                                                        :disabled="checkDisable('pre_division_id', 'address')"
                                                        :options="divisionList"
                                                        id="pre_division_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="ItemShow">
                                                <ValidationProvider name="District" vid="pre_district_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="pre_district_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.pre_district_id"
                                                        :disabled="checkDisable('pre_district_id', 'address')"
                                                        :options="districtList"
                                                        id="pre_district_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="PauroshobaItemShow || UnionItemShow">
                                                <ValidationProvider name="Upazila" vid="upazila_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="upazila_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.pre_upazilla_id"
                                                        :disabled="checkDisable('pre_upazilla_id', 'address')"
                                                        :options="upazilaList"
                                                        id="upazila_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="UnionItemShow && nonMandatory">
                                                <ValidationProvider name="Union" vid="union_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="union_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_union.union')}}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.pre_union_id"
                                                        :options="unionList"
                                                        id="union_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="CityCorpItemShow">
                                                <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="city_corporation_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.pre_city_corporation_id"
                                                        :options="cityCorporationList"
                                                        :disabled="checkDisable('pre_city_corporation_id', 'address')"
                                                        id="city_corporation_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="PauroshobaItemShow && nonMandatory">
                                                <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="pauroshoba_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('org_pro.pauroshoba') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.pre_pauroshoba_id"
                                                        :options="pauroshobaList"
                                                        id="pauroshoba_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="ItemShow && nonMandatory">
                                                <ValidationProvider name="Ward" vid="ward_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="ward_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('org_pro.ward') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.pre_ward_id"
                                                        :options="wardList"
                                                        id="ward_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </fieldset>
                                    <fieldset class="p-2 w-100" v-show="nonMandatory">
                                        <legend class="px-2 w-50 shadow-sm">{{ $t('externalTraining.parmanent_address') }}</legend>
                                        <b-row class="mb-3 ml-2 mr-2">
                                            <b-col>
                                                <b-form-checkbox
                                                    id="checkbox-1"
                                                    v-model="profileInfo.address.same_as_pre_address"
                                                    :disabled="sameCheckDisable('same_as_pre_address', 'address')"
                                                    name="checkbox-1"
                                                    value="1"
                                                    unchecked-value="0"
                                                    class="checkboxes"
                                                >
                                                    <p class="mb-0" style="color: #3f414d;font-size: 16px;font-weight: 600;">{{ $t('externalTraining.same_as') }}</p>
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-3 ml-2 mr-2">
                                            <b-col sm="4">
                                                <ValidationProvider name="Area Type" vid="area_type_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="area_type_id "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.per_area_type_id"
                                                        id="area_type_id"
                                                        :options="getAreaTypeList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="sameCheckDisable('per_area_type_id', 'address')"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="ItemShow1">
                                                <ValidationProvider name="Division" vid="division_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="division_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.per_division_id"
                                                        :options="divisionList"
                                                        id="division_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="sameCheckDisable('per_division_id', 'address')"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="ItemShow1">
                                                <ValidationProvider name="District" vid="district_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="per_district_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.per_district_id"
                                                        :options="districtList1"
                                                        id="per_district_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="sameCheckDisable('per_district_id', 'address')"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="PauroshobaItemShow1 || UnionItemShow1">
                                                <ValidationProvider name="Upazila" vid="upazila_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="upazila_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.per_upazilla_id"
                                                        :options="upazilaList1"
                                                        id="upazila_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="sameCheckDisable('per_upazilla_id', 'address')"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="UnionItemShow1 && nonMandatory">
                                                <ValidationProvider name="Union" vid="union_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="union_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_union.union')}}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.per_union_id"
                                                        :options="unionList1"
                                                        id="union_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="CityCorpItemShow1">
                                                <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="city_corporation_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.per_city_corporation_id"
                                                        :options="cityCorporationList1"
                                                        id="city_corporation_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :disabled="sameCheckDisable('per_city_corporation_id', 'address')"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="PauroshobaItemShow1 && nonMandatory">
                                                <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="pauroshoba_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('org_pro.pauroshoba') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.per_pauroshoba_id"
                                                        :options="pauroshobaList1"
                                                        id="pauroshoba_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="4" v-show="ItemShow1 && nonMandatory">
                                                <ValidationProvider name="Ward" vid="ward_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="ward_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('org_pro.ward') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="profileInfo.address.per_ward_id"
                                                        :options="wardList1"
                                                        id="ward_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </fieldset>
                                    <fieldset class="p-2 w-100" v-show="nonMandatory">
                                        <legend class="px-2 w-50 shadow-sm">{{ $t('externalTraining.academic_info') }}</legend>
                                        <b-row  class="mb-3 ml-2 mr-2">
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Degree" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="highest_degree"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.highest_degree')}}  {{$t('globalTrans.enn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="name_of_highest_degree"
                                                        v-model="profileInfo.name_of_highest_degree"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Degree Bn" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="highest_degree"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.highest_degree')}}  {{$t('globalTrans.bnn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="name_of_highest_degree_bn"
                                                        v-model="profileInfo.name_of_highest_degree_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="University/Board" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="board_university"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.board_university')}}  {{$t('globalTrans.enn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="board"
                                                        v-model="profileInfo.board"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="University/Board Bn" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="board_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.board_university')}}  {{$t('globalTrans.bnn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="board"
                                                        v-model="profileInfo.board_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Passing Year" vid="passing_year" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="passing_year"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.passing_year')}}
                                                    </template>
                                                    <b-form-input
                                                        id="passing_year"
                                                        v-model="profileInfo.passing_year"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <b-col lg="6" sm="12">
                                                <ValidationProvider name="Group" vid="prof_group" rules="">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="prof_group"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.group')}}  {{$t('globalTrans.enn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="prof_group"
                                                        v-model="profileInfo.prof_group"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Group Bn" vid="prof_group_bn" rules="">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="prof_group_bn"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.group')}}  {{$t('globalTrans.bnn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="prof_group_bn"
                                                        v-model="profileInfo.prof_group_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="CGPA" vid="cgpa" rules="">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="cgpa"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.cgpa')}}
                                                    </template>
                                                    <b-form-input
                                                        id="cgpa"
                                                        v-model="profileInfo.cgpa"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Attachment" vid="certificate_attachment" rules="">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    :label="$t('globalTrans.attachment')"
                                                    label-for="certificate_attachment"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{$t('globalTrans.attachment')}}
                                                    </template>
                                                    <b-form-file
                                                        id="certificate_attachment"
                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                        v-model="profileInfo.certificate_file"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                    <a v-if="profileInfo.certificate_attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/iab/personal_info/certificate/' + profileInfo.certificate_attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                                    <p class="text-danger mt-2" style="font-size: 10px;">{{$t('elearning_iabm.training_file_validation_format')}}</p>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- <b-col lg="6" sm="12">
                                                <ValidationProvider name="Expertise" vid="expertise_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="expertise_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_config.expertise')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.expertise_id"
                                                        :options="trainingExpertiseList"
                                                        id="expertise_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.expertise_id === 999">
                                                <ValidationProvider name="Expertise Name (En)" vid='expertise_name_en' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="expertise_name_en"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.expertise_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="expertise_name_en"
                                                        v-model="profileInfo.expertise_name_en"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.expertise_id === 999">
                                                <ValidationProvider name="Expertise Name (Bn)" vid='expertise_name_bn' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="expertise_name_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.expertise_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="expertise_name_bn"
                                                        v-model="profileInfo.expertise_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col> -->
                                        </b-row>
                                    </fieldset>
                                    <div>
                                        <fieldset class="p-2 w-100">
                                            <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.expertise_info')}}</legend>
                                            <b-row class="p-3" v-for="(expertise_data, index) in profileInfo.expertise_infos" :key="index">
                                                <b-col lg="6" sm="12">
                                                <ValidationProvider name="Expertise" :vid="'expertise_id' + index" :rules="(profileInfo.applied_org_id == 6) ? '' : 'required|min_value:1'">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="expertise_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.expertise_field')}} <span class="text-danger">{{ profileInfo.applied_org_id == 6 ? '' : '*' }}</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="expertise_data.expertise_id"
                                                        :options="trainingExpertiseList"
                                                        :name="'expertise_infos['+index+'][expertise_id]'"
                                                        :id="'expertise_infos['+index+'][expertise_id]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12" v-if="expertise_data.expertise_id === 999">
                                                <ValidationProvider name="Expertise Name (En)" :vid="'expertise_name_en' + index" :rules="(profileInfo.applied_org_id == 6) ? '' : 'required'">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="expertise_name_en"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.expertise_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">{{ profileInfo.applied_org_id == 6 ? '' : '*' }}</span>
                                                    </template>
                                                    <b-form-input
                                                        v-model="expertise_data.expertise_name_en"
                                                        :name="'expertise_infos['+index+'][expertise_name_en]'"
                                                        :id="'expertise_infos['+index+'][expertise_name_en]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12" v-if="expertise_data.expertise_id === 999">
                                                <ValidationProvider name="Expertise Name (Bn)" :vid="'expertise_name_bn' + index" :rules="(profileInfo.applied_org_id == 6) ? '' : 'required'">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="expertise_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.expertise_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">{{ profileInfo.applied_org_id == 6 ? '' : '*' }}</span>
                                                    </template>
                                                    <b-form-input
                                                        v-model="expertise_data.expertise_name_bn"
                                                        :name="'expertise_infos['+index+'][expertise_name_bn]'"
                                                        :id="'expertise_infos['+index+'][expertise_name_bn]'"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Authenticator Name (En)" :vid="'authenticator_name_en' + index" :rules="(profileInfo.applied_org_id == 6) ? '' : 'required'">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="authenticator_name_en"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('elearning_iabm.authenticator_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">{{ profileInfo.applied_org_id == 6 ? '' : '*' }}</span>
                                                        </template>
                                                        <b-form-input
                                                            v-model="expertise_data.authenticator_name_en"
                                                            :name="'expertise_infos['+index+'][authenticator_name_en]'"
                                                            :id="'expertise_infos['+index+'][authenticator_name_en]'"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Authenticator Name (Bn)" :vid="'authenticator_name_bn' + index" :rules="(profileInfo.applied_org_id == 6) ? '' : 'required'">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="authenticator_name_bn"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{ $t('elearning_iabm.authenticator_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">{{ profileInfo.applied_org_id == 6 ? '' : '*' }}</span>
                                                        </template>
                                                        <b-form-input
                                                            v-model="expertise_data.authenticator_name_bn"
                                                            :name="'expertise_infos['+index+'][authenticator_name_bn]'"
                                                            :id="'expertise_infos['+index+'][authenticator_name_bn]'"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                    </b-col>
                                                <b-col lg="6" sm="12">
                                                <input type="hidden" :name="'expertise_infos['+index+'][id]'" v-model="expertise_data.id"/>
                                                <input type="hidden" :name="'expertise_infos['+index+'][pre_attachment]'" v-model="expertise_data.attachment"/>
                                                <ValidationProvider :vid="'expertise_infos' + '.' + index + '.' + 'attachment'" :rules="(profileInfo.applied_org_id == 6) ? '' : 'required'"  name="Attachment">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="attachment"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.attach_proof')}} <span class="text-danger">{{ profileInfo.applied_org_id == 6 ? '' : '*' }}</span>
                                                    </template>
                                                    <b-form-file
                                                        v-model="expertise_data.attachment"
                                                        :name="'expertise_infos['+index+'][attachment]'"
                                                        :id="'expertise_infos['+index+'][attachment]'"
                                                        accept=".png, .jpg, .jpeg, .pdf"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="error invalid-feedback d-block" role="alert">
                                                        {{ errors[0] }}
                                                    </div>
                                                    <a v-if="expertise_data.id && expertise_data.attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + expertise_data.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                                                    <p class="text-danger mt-2" style="font-size: 10px;">{{ $i18n.locale == 'en' ? '[Maximum file size is 2 MB and file format is PNF/JPG/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 2 MB এবং ফাইল ফরমেট PNF/JPG/JPEG/PDF]' }}</p>
                                                    </b-form-group>
                                                </ValidationProvider>
                                                </b-col>
                                                <b-col sm="12" class="mt-0 text-right">
                                                    <b-button v-show="index == profileInfo.expertise_infos.length-1" variant=" iq-bg-success" @click="addItem()"><i class="ri-add-line m-0"></i> {{ $t('globalTrans.add') }}</b-button>
                                                    <b-button v-show="index || ( !index && profileInfo.expertise_infos.length > 1)" variant=" iq-bg-danger" @click="removeAttachment(index, expertise_infos)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                </b-col>
                                            </b-row>
                                        </fieldset>
                                    </div>
                                    <fieldset class="p-2 w-100">
                                        <legend class="px-2 w-50 shadow-sm">{{ $t('externalTraining.professional_info') }}</legend>
                                        <b-row class="mb-3 ml-2 mr-2">
                                            <b-col lg="4" md="4" sm="12" xs="12">
                                                <b-form-checkbox
                                                    class="mt-2"
                                                    id="same_as_mobile_number"
                                                    v-model="profileInfo.professional_info.retired"
                                                    :value=1
                                                    :unchecked-value=0
                                                >
                                                    {{$t('elearning_iabm.retired')}}
                                                </b-form-checkbox>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-3 ml-2 mr-2" v-if="profileInfo.professional_info.retired">
                                            <b-col lg="12" md="12" sm="12" xs="12"><h5 style="text-align:left"> {{$t('elearning_iabm.last_office_information')}}</h5></b-col>
                                            <hr/>
                                        </b-row>
                                        <b-row class="mb-3 ml-2 mr-2">
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Resource Person Type" vid="resource_person_type" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="profession_type"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.resource_person_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.profession_type"
                                                        :disabled="checkDisable('profession_type', 'professional_info')"
                                                        :options="professionTypeList"
                                                        id="profession_type"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.professional_info.profession_type === 1 || profileInfo.professional_info.profession_type === 2">
                                                <ValidationProvider name="Job Type" vid="job_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="job_type_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.job_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.job_type_id"
                                                        :disabled="checkDisable('job_type_id', 'professional_info')"
                                                        :options="jobTypeList"
                                                        id="job_type_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.professional_info.job_type_id == 1 && (profileInfo.professional_info.profession_type == 1 ||  profileInfo.professional_info.profession_type == 2)">
                                                <ValidationProvider name="Cader Name (En)" vid='cader_name' rules="">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="cader_name"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.cader_name')}} {{ $t('globalTrans.enn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="cader_name"
                                                        v-model="profileInfo.professional_info.cader_name"
                                                        :disabled="checkDisable('cader_name', 'professional_info')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.professional_info.job_type_id == 1 && (profileInfo.professional_info.profession_type == 1 ||  profileInfo.professional_info.profession_type == 2)">
                                                <ValidationProvider name="Cader Name (Bn)" vid='cader_name_bn' rules="">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="cader_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_iabm.cader_name')}} {{ $t('globalTrans.bnn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="cader_name_bn"
                                                        v-model="profileInfo.professional_info.cader_name_bn"
                                                        :disabled="checkDisable('cader_name_bn', 'professional_info')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.professional_info.job_type_id === 1 && (profileInfo.professional_info.profession_type === 1 ||  profileInfo.professional_info.profession_type === 2)">
                                                <ValidationProvider name="Batch" vid="batch" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="batch"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{ $t('elearning_iabm.batch')}}
                                                        </template>
                                                        <b-form-input
                                                            id="batch"
                                                            v-model="profileInfo.professional_info.batch"
                                                            :disabled="checkDisable('batch', 'professional_info')"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <b-col lg="6" sm="12" v-if="profileInfo.professional_info.profession_type == 2 || profileInfo.professional_info.profession_type == 3">
                                                <ValidationProvider name="Organization Name (En)" vid='other_org_name' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="other_org_name"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="other_org_name"
                                                        v-model="profileInfo.professional_info.other_org_name"
                                                        :disabled="checkDisable('other_org_name', 'professional_info')"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.professional_info.profession_type == 2 || profileInfo.professional_info.profession_type == 3">
                                                <ValidationProvider name="Organization Name (Bn)" vid='other_org_name_bn' rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="other_org_name_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="other_org_name_bn"
                                                        :disabled="checkDisable('other_org_name_bn', 'professional_info')"
                                                        v-model="profileInfo.professional_info.other_org_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.professional_info.profession_type == 1">
                                                <ValidationProvider name="Organization" vid="region_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="org_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalUserIrrigation.organization')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.org_id"
                                                        :disabled="checkDisable('org_id', 'professional_info')"
                                                        :options="orgList"
                                                        id="org_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- <b-col lg="6" sm="6" v-if="profileInfo.professional_info.profession_type == 1 && profileInfo.professional_info.not_here_office !== 1">
                                                <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="office_type_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="profileInfo.professional_info.office_type_id"
                                                            :disabled="checkDisable('office_type_id', 'professional_info')"
                                                            :options="officeTypeList"
                                                            id="office_type_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="6" v-if="profileInfo.professional_info.profession_type == 1 && profileInfo.professional_info.not_here_office !== 1">
                                                <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="office_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="profileInfo.professional_info.office_id"
                                                            :options="officeList"
                                                            id="office_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col> -->
                                            <!-- <b-col lg="12" sm="12" v-if="profileInfo.professional_info.profession_type == 1" class="mb-3">
                                                <b-form-checkbox
                                                    class="mt-2"
                                                    id="not_here_office"
                                                    v-model="profileInfo.professional_info.not_here_office"
                                                    :disabled="checkDisable('not_here_office', 'professional_info')"
                                                    :value=1
                                                    :unchecked-value=0
                                                >
                                                <span class="text-danger">{{$t('elearning_iabm.not_here_office')}}</span>
                                                </b-form-checkbox>
                                            </b-col> -->
                                            <!-- <b-col lg="6" sm="12"  v-if="profileInfo.professional_info.not_here_office == 1"> -->
                                            <b-col lg="6" sm="12">
                                            <ValidationProvider name="Office Name (En)" vid='office_name' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="office_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_iabm.office_name_address')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="office_name"
                                                    v-model="profileInfo.professional_info.office_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- <b-col lg="6" sm="12" v-if="profileInfo.professional_info.not_here_office == 1"> -->
                                            <b-col lg="6" sm="12">
                                            <ValidationProvider name="Office Name (Bn)" vid='office_name_bn' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="office_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_iabm.office_name_address')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="office_name_bn"
                                                    v-model="profileInfo.professional_info.office_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- <b-col lg="6" sm="12" v-if="profileInfo.professional_info.profession_type == 1 && profileInfo.professional_info.not_here_office == 0">
                                                <ValidationProvider name="Office Address" vid='office_mail_address'>
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="office_mail_address"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.office_mail')}}   {{$t('globalTrans.enn')}}
                                                    </template>
                                                    <b-form-textarea
                                                        id="office_mail_address"
                                                        v-model="profileInfo.professional_info.office_mail_address"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.professional_info.profession_type == 1 && profileInfo.professional_info.not_here_office == 0">
                                                <ValidationProvider name="Office Address" vid='office_mail_address_bn'>
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="office_mail_address_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.office_mail')}}   {{$t('globalTrans.bnn')}}
                                                    </template>
                                                    <b-form-textarea
                                                        id="office_mail_address_bn"
                                                        v-model="profileInfo.professional_info.office_mail_address_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col> -->
                                            <!-- <b-col lg="6" sm="12" v-if="profileInfo.professional_info.profession_type == 1 && profileInfo.professional_info.not_here_designation !== 1">
                                                <ValidationProvider name="Designation" vid="designation_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="designation_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.designation')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.designation_id"
                                                        :options="designationList"
                                                        id="designation_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col> -->
                                            <!-- <b-col lg="12" sm="12" v-if="profileInfo.professional_info.profession_type == 1" class="mb-3">
                                                <b-form-checkbox
                                                    class="mt-2"
                                                    id="not_here_designation"
                                                    v-model="profileInfo.professional_info.not_here_designation"
                                                    :disabled="checkDisable('not_here_designation', 'professional_info')"
                                                    :value=1
                                                    :unchecked-value=0
                                                >
                                                <span class="text-danger">{{$t('elearning_iabm.not_here_designation')}}</span>
                                                </b-form-checkbox>
                                            </b-col> -->
                                            <!-- <b-col lg="6" sm="12"  v-if="profileInfo.professional_info.not_here_designation == 1 || profileInfo.professional_info.profession_type == 2 || profileInfo.professional_info.profession_type == 3"> -->
                                            <!-- <b-col lg="6" sm="12" v-if="profileInfo.professional_info.not_here_designation == 1 || profileInfo.professional_info.profession_type == 2 || profileInfo.professional_info.profession_type == 3"> -->
                                            <b-col lg="6" sm="12">
                                            <ValidationProvider name="Designation Name (En)" vid='designation_en' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="designation_en"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.designation')}} {{ $t('globalTrans.enn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="designation_en"
                                                    v-model="profileInfo.professional_info.designation_en"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <!-- <b-col lg="6" sm="12" v-if="profileInfo.professional_info.not_here_designation == 1 || profileInfo.professional_info.profession_type == 2 || profileInfo.professional_info.profession_type == 3"> -->
                                            <b-col lg="6" sm="12">
                                            <ValidationProvider name="Designation Name (Bn)" vid='designation_bn' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="designation_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.designation')}} {{ $t('globalTrans.bnn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="designation_bn"
                                                    v-model="profileInfo.professional_info.designation_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="(profileInfo.professional_info.profession_type === 1 || profileInfo.professional_info.profession_type === 2) && nonMandatory">
                                                <ValidationProvider name="Service Id" vid="service_id" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="service_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.service_id')}}
                                                    </template>
                                                    <b-form-input
                                                        id="service_id"
                                                        v-model="profileInfo.professional_info.service_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="profileInfo.professional_info.profession_type === 1 || profileInfo.professional_info.profession_type === 2">
                                                <ValidationProvider name="Pay Grade" vid="grade_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="pay_grade"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.pay_grade')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.grade_id"
                                                        :disabled="checkDisable('grade_id', 'professional_info')"
                                                        :options="gradeList"
                                                        id="pay_grade"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="!profileInfo.professional_info.retired && profileInfo.professional_info.profession_type && nonMandatory">
                                                <ValidationProvider name="Controlling Authority (Designation)" vid="controlling_authority">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="controlling_authority"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.designation_c')}}   {{$t('globalTrans.enn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="controlling_authority"
                                                        v-model="profileInfo.professional_info.controlling_authority"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12"  v-if="!profileInfo.professional_info.retired && profileInfo.professional_info.profession_type && nonMandatory">
                                                <ValidationProvider name="Controlling Authority (Designation) (Bn)" vid="controlling_authority_bn">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="controlling_authority_bn"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.designation_c')}}   {{$t('globalTrans.bnn')}}
                                                    </template>
                                                    <b-form-input
                                                        id="controlling_authority_bn"
                                                        v-model="profileInfo.professional_info.controlling_authority_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="!profileInfo.professional_info.retired && profileInfo.professional_info.profession_type && nonMandatory">
                                                <ValidationProvider name="Telephone" vid='telephone_c' rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="telephone_c"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.telephone_c')}}
                                                    </template>
                                                    <b-form-input
                                                        type="text"
                                                        id="telephone_c"
                                                        v-model="profileInfo.professional_info.telephone"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-if="!profileInfo.professional_info.retired && nonMandatory">
                                                <ValidationProvider name="Email" vid='email_c' rules="email|min:3">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="email_c"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.email_c')}}
                                                    </template>
                                                    <b-form-input
                                                        id="email_c"
                                                        v-model="profileInfo.professional_info.email"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12" v-show="profileInfo.professional_info.retired">
                                                <ValidationProvider name="Retirement Date" vid="retirement_date" rules="">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="retirement_date"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{ $t('elearning_iabm.retirement_date')}}
                                                        </template>
                                                        <b-form-input
                                                            class="fromDate"
                                                            v-model="profileInfo.professional_info.retirement_date"
                                                            :placeholder="$t('elearning_iabm.retirement_date')"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col sm="6" v-show="nonMandatory">
                                                <ValidationProvider name="Specialist" vid='specialist'>
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="specialist"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('elearning_iabm.specialist')}}
                                                        </template>
                                                        <b-form-textarea
                                                            id="specialist"
                                                            v-model="profileInfo.professional_info.specialist"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </fieldset>
                                    <fieldset class="p-2 w-100" v-if="profileInfo.applied_org_id === 6">
                                        <legend class="px-2 w-50 shadow-sm">{{$t('elearning_iabm.work_space_info')}}</legend>
                                        <b-row class="p-3">
                                            <b-col lg="12" sm="12">
                                            {{ $t('elearning_iabm.apply_note') }}
                                            </b-col>
                                        </b-row>
                                        <b-row class="p-3">
                                            <b-col lg="6" sm="6">
                                                <ValidationProvider name="Office Type" vid="recomend_office_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="recomend_office_type_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                        {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="profileInfo.recomend_office_type_id"
                                                            :options="brriOfficeTypeList"
                                                            id="recomend_office_type_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="6">
                                            <ValidationProvider name="Office" vid="recomend_office_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="recomend_office_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.recomend_office_id"
                                                        :options="brriOfficeList"
                                                        id="recomend_office_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row class="p-3">
                                            <b-col lg="6" sm="6">
                                            <ValidationProvider name="Designation" vid="recomend_designation_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="recomend_designation_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('externalTraining.designation')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="profileInfo.recomend_designation_id"
                                                    :options="brriDesignationList"
                                                    id="recomend_designation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </fieldset>
                                    <b-row>
                                        <b-col lg="9" sm="12">
                                            <ValidationProvider name="Applied Organization" vid="applied_org_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    :label-cols-sm="$i18n.locale === 'bn' ? 4 : 5"
                                                    label-for="applied_org_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('elearning_iabm.registration_note_new')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="profileInfo.applied_org_id"
                                                    disabled
                                                    :options="orgList"
                                                    id="applied_org_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            <!-- <p class="text-danger"><b>{{ $t('elearning_iabm.registration_note')}}</b></p> -->
                                            <!-- <p><b>{{ $t('elearning_iabm.registration_note_2')}} (<span class="text-danger">*</span>) {{ $t('elearning_iabm.registration_note_3')}}</b></p> -->
                                        </b-col>
                                    </b-row>
                                    <div class="text-right mb-3">
                                        <b-button-group>
                                            <!-- <b-button type="submit"
                                                :disabled="saved"
                                                variant="success"
                                                title="Final Save cannot be reverted!"
                                                class="ml-1 rounded">{{ $t('externalUserIrrigation.final_save') }}
                                                <i class="fas fa-lock ml-1"></i>
                                            </b-button> -->
                                            <b-button type="submit"
                                                variant="success"
                                                title="Final Save cannot be reverted!"
                                                class="ml-1 rounded">{{ $t('externalUserIrrigation.final_save') }}
                                                <i class="fas fa-lock ml-1"></i>
                                            </b-button>
                                            <!-- <b-button type="submit"
                                                variant="danger"
                                                title="Final Save cannot be reverted!"
                                                class="ml-1 rounded">{{ $t('externalUserIrrigation.final_save') }}
                                                <i class="fas fa-lock ml-1"></i>
                                            </b-button> -->
                                        </b-button-group>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('externalTraining.profile_info') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details ref="details"/>
        </b-modal>
    </b-container>
</template>
<style>
.checkboxes label.custom-control-label {
    color: white !important;
    font-size: 13px !important;
    font-weight: bold !important;
}
select#profession_type {
    text-transform: none !important;
}
</style>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { profileStore, profileInfo } from '../../api/routes'
import { Common } from '@/mixins/helper-functions'
import flatpickr from 'flatpickr'
import Details from './TrainerDetails'
import { mapGetters } from 'vuex'
import { EventBus } from '@/EventBusLayout'

export default {
    mixin: [Common],
    components: {
        Details
    },
    data () {
        return {
            approveCheck: 0,
            clickCount: 0,
            nonMandatory: false,
            ItemShow: false,
            CityCorpItemShow: false,
            PauroshobaItemShow: false,
            UnionItemShow: false,
            ItemShow1: false,
            CityCorpItemShow1: false,
            PauroshobaItemShow1: false,
            UnionItemShow1: false,
            overlay: false,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
            profileInfo: {
                recomend_office_type_id: 0,
                recomend_office_id: 0,
                recomend_designation_id: 0,
                name: '',
                name_bn: '',
                name_of_father_husband: '',
                name_of_father_husband_bn: '',
                mother_name: '',
                mother_name_bn: '',
                date_of_birth: '',
                email: '',
                nid: '',
                mobile: '',
                gender: 0,
                applied_org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
                applied_type: this.$store.state.ExternalUserIrrigation.panelObj.trainingType,
                marital_status: 0,
                blood_group: 0,
                religion: 0,
                emergency_name: '',
                emergency_name_bn: '',
                emergency_relation: '',
                emergency_relation_bn: '',
                emergency_contact: '',
                name_of_highest_degree: '',
                name_of_highest_degree_bn: '',
                board: '',
                board_bn: '',
                passing_year: '',
                prof_group: '',
                prof_group_bn: '',
                cgpa: '',
                expertise_infos: [
                    {
                        id: '',
                        expertise_id: 0,
                        expertise_name_en: '',
                        expertise_name_bn: '',
                        authenticator_name_en: '',
                        authenticator_name_bn: '',
                        attachment: null,
                        pre_attachment: []
                    }
                ],
                nid_file: [],
                image_file: [],
                signature_file: [],
                certificate_file: [],
                first_entry: 1,
                expertise_id: 0,
                expertise_name_en: '',
                expertise_name_bn: '',
                professional_info: {
                    org_id: 0,
                    office_type_id: 0,
                    office_id: 0,
                    other_org_name: '',
                    other_org_name_bn: '',
                    not_here_office: 1,
                    office_name: '',
                    office_name_bn: '',
                    not_here_designation: 1,
                    designation_en: '',
                    designation_bn: '',
                    profession_type: 0,
                    job_type_id: 0,
                    designation_id: 0,
                    grade_id: 0,
                    service_id: '',
                    batch: '',
                    cader_name: '',
                    cader_name_bn: '',
                    controlling_authority: '',
                    controlling_authority_bn: '',
                    telephone: '',
                    email: '',
                    specialist: '',
                    office_mail_address: '',
                    office_mail_address_bn: ''
                },
                address: {
                    pre_area_type_id: 0,
                    pre_division_id: 0,
                    pre_district_id: 0,
                    pre_upazilla_id: 0,
                    pre_ward_id: '',
                    pre_union_id: '',
                    pre_city_corporation_id: '',
                    pre_pauroshoba_id: '',
                    same_as_pre_address: 0,
                    per_area_type_id: 0,
                    per_division_id: 0,
                    per_district_id: 0,
                    per_upazilla_id: 0,
                    per_city_corporation_id: '',
                    per_pauroshoba_id: '',
                    per_ward_id: '',
                    per_union_id: ''
                },
                is_draft: 1,
                status: 1
            },
            saved: false,
            trainingData: {
                professional_info: {
                    retired: 0,
                    org_id: 0,
                    office_type_id: 0,
                    office_id: 0,
                    other_org_name: '',
                    other_org_name_bn: '',
                    not_here_office: 1,
                    office_name: '',
                    office_name_bn: '',
                    not_here_designation: 1,
                    designation_en: '',
                    designation_bn: '',
                    profession_type: 0,
                    job_type_id: 0,
                    designation_id: 0,
                    grade_id: 0,
                    service_id: '',
                    batch: '',
                    cader_name: '',
                    cader_name_bn: '',
                    controlling_authority: '',
                    telephone: '',
                    email: '',
                    office_mail_address: ''
                },
                address: {
                    pre_area_type_id: 0,
                    pre_division_id: 0,
                    pre_district_id: 0,
                    pre_upazilla_id: 0,
                    pre_ward_id: '',
                    pre_union_id: '',
                    pre_city_corporation_id: '',
                    pre_pauroshoba_id: '',
                    same_as_pre_address: 0,
                    per_area_type_id: 0,
                    per_division_id: 0,
                    per_district_id: 0,
                    per_upazilla_id: 0,
                    per_city_corporation_id: '',
                    per_pauroshoba_id: '',
                    per_ward_id: '',
                    per_union_id: ''
                }
            },
            brriOfficeTypeList: [],
            brriOfficeList: [],
            brriDesignationList: [],
            expertise_infos_remove: [],
            officeTypeList: [],
            officeList: [],
            districtList: [],
            upazilaList: [],
            unionList: [],
            wardList: [],
            cityCorporationList: [],
            pauroshobaList: [],
            districtList1: [],
            upazilaList1: [],
            unionList1: [],
            wardList1: [],
            cityCorporationList1: [],
            pauroshobaList1: [],
            zoneList: [],
            unitList: [],
            designationList: [],
            id: '',
            image: [],
            invalidNid: 1,
            loading: false
        }
    },
    created () {
        this.loadProfile()
        this.getBrriOfficeTypeList(this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg)
        this.getBrriDesignationByOrg(this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg)
    },
    mounted () {
        flatpickr('.fromDate', {})
    },
    computed: {
        authenticatorNameList: function () {
            return [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'সার্টিফিকেট' : 'Certificate',
                    text_en: 'Certificate',
                    text_bn: 'সার্টিফিকেট'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'প্রত্যয়ন পত্র' : 'Approval',
                    text_en: 'Approval',
                    text_bn: 'প্রত্যয়ন পত্র'
                }
            ]
        },
        yesNoList () {
            return [
                { text: this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes', value: 1 },
                { text: this.$i18n.locale === 'bn' ? 'না' : 'No', value: 0 }
            ]
        },
        professionTypeList: function () {
            return [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
                    text_en: 'MoA/Organization under MoA',
                    text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
                    text_en: 'Other Government Organization',
                    text_bn: 'অন্যান্য সরকারী সংস্থা'
                },
                {
                    value: 3,
                    text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
                    text_en: 'Non-Goverment Organization',
                    text_bn: 'বেসরকারি সংস্থা'
                }
            ]
            },
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        userTypeList () {
            const userList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Resource Person' : 'রিসোর্স পারসন' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
            ]
            return userList
        },
        orgList () {
            return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        jobTypeList () {
            return this.$store.state.ExternalUserTraining.traineeTranerObj.jobTypeList
        },
        getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.wardTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        },
        divisionList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
        },
        genderList () {
            const gender = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
            ]
            return gender
        },
        maritalList () {
            const status = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Married' : 'বিবাহিত' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Unmarried' : 'অবিবাহিত' }
            ]
            return status
        },
        bloodGroupList () {
            const group = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'A-' : 'এ-' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'B-' : 'বি-' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
                { value: 6, text: this.$i18n.locale === 'en' ? 'O-' : 'ও-' },
                { value: 7, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' },
                { value: 8, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি-' }
            ]
            return group
        },
        religionList () {
           const gender = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Muslim' : 'মুসলিম' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Hindu' : 'হিন্দু' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Christian' : 'খ্রিস্টান' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Buddhist' : 'বৌদ্ধ ' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
            ]
            return gender
        },
        serviceList () {
            return null
        },
        degreeList () {
            return null
        },
        universityList () {
            return null
        },
        gradeList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.gradeList
        },
        commonProfile () {
            return this.$store.state.ExternalUserIrrigation.commonProfile
        },
        trainingExpertiseList () {
            // const expertiseData = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingExpertiseList.filter(item => item.status === 1 && parseInt(item.org_id) === parseInt(this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg))
            const expertiseData = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingExpertiseList.filter(item => item.status === 1)
            expertiseData.push(
                {
                    value: 999,
                    text: this.$i18n.locale === 'bn' ? 'অন্যান্য' : 'Other',
                    text_en: 'Other',
                    text_bn: 'অন্যান্য'
                }
            )
            return expertiseData
        }
    },
    watch: {
        'profileInfo.address.pre_area_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getAreaTypeData(this.profileInfo.address.pre_area_type_id)
            }
        },
        'profileInfo.address.per_area_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getAreaTypeData1(this.profileInfo.address.per_area_type_id)
            }
        },
        'profileInfo.address.pre_division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'profileInfo.address.pre_district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'profileInfo.address.pre_upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPauroshobaList(newVal)
        },
        'profileInfo.address.pre_city_corporation_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByCityCorportaion(newVal)
        },
        'profileInfo.address.pre_pauroshoba_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByPauroshoba(newVal)
        },
        'profileInfo.address.pre_union_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByUnion(newVal)
        },
        'profileInfo.address.per_division_id': function (newVal, oldVal) {
            this.districtList1 = this.getDistrictList(newVal)
        },
        'profileInfo.address.per_district_id': function (newVal, oldVal) {
            this.cityCorporationList1 = this.getCityCorporationList(newVal)
            this.upazilaList1 = this.getUpazilaList(newVal)
        },
        'profileInfo.address.per_upazilla_id': function (newVal, oldVal) {
            this.unionList1 = this.getUnionList(newVal)
            this.pauroshobaList1 = this.getPauroshobaList(newVal)
        },
        'profileInfo.address.per_city_corporation_id': function (newVal, oldVal) {
            this.wardList1 = this.getWardListByCityCorportaion(newVal)
        },
        'profileInfo.address.per_pauroshoba_id': function (newVal, oldVal) {
            this.wardList1 = this.getWardListByPauroshoba(newVal)
        },
        'profileInfo.address.per_union_id': function (newVal, oldVal) {
            this.wardList1 = this.getWardListByUnion(newVal)
        },
        'profileInfo.professional_info.org_id': function (newVal, oldVal) {
            if (newVal !== 0) {
                this.designationList = this.getDesignationByOrg(newVal)
                this.officeTypeList = this.getOfficeTypeList(newVal)
            }
        },
        'profileInfo.professional_info.office_type_id': function (newValue) {
            if (newValue !== 0) {
                this.officeList = this.getParentOfficeList(newValue)
            }
        },
        'profileInfo.address.same_as_pre_address': function (newVal, oldVal) {
            if (newVal === '1') {
                this.profileInfo.address.per_area_type_id = this.profileInfo.address.pre_area_type_id
                this.profileInfo.address.per_division_id = this.profileInfo.address.pre_division_id
                this.profileInfo.address.per_district_id = this.profileInfo.address.pre_district_id
                this.profileInfo.address.per_upazilla_id = this.profileInfo.address.pre_upazilla_id
                this.profileInfo.address.per_city_corporation_id = this.profileInfo.address.pre_city_corporation_id
                this.profileInfo.address.per_pauroshoba_id = this.profileInfo.address.pre_pauroshoba_id
                this.profileInfo.address.per_ward_id = this.profileInfo.address.pre_ward_id
                this.profileInfo.address.per_union_id = this.profileInfo.address.pre_union_id
                this.getAreaTypeData1(this.profileInfo.address.pre_area_type_id)
            }
        },
        'profileInfo.recomend_office_type_id': function (newValue) {
            if (newValue !== 0) {
                this.brriOfficeList = this.getParentOfficeList(newValue)
            }
        }

    },
    methods: {
        addItem () {
            this.profileInfo.expertise_infos.push({
                id: '',
                expertise_id: 0,
                expertise_name_en: '',
                expertise_name_bn: '',
                authenticator_name_en: '',
                authenticator_name_bn: '',
                attachment: null,
                pre_attachment: []
            })
        },
        removeAttachment (index, data) {
            this.profileInfo.expertise_infos.splice(index, 1)
            this.expertise_infos_remove.push(data)
        },
        nidRules (e) {
            if (e.length) {
                this.invalidNid = (parseInt(e.length) === 10 || parseInt(e.length) === 13 || parseInt(e.length) === 17) ? 0 : 1
            } else {
                this.invalidNid = 1
            }
        },
        sameCheckDisable (field, type = null) {
            if (field !== 'same_as_pre_address') {
                if (parseInt(this.profileInfo.address.same_as_pre_address) === 1) {
                    return true
                }
            }
            return this.checkDisable(field, type)
        },
        checkDisable (field, type = null) {
            let mainData = []
            if (type) {
                if (type === 'address') {
                    mainData = this.trainingData.address
                }
                if (type === 'professional_info') {
                    mainData = this.trainingData.professional_info
                }
            } else {
                mainData = this.trainingData
            }
            const data = mainData[field]
            if (data) {
                return true
            }
            return false
        },
        loadProfile () {
            this.loading = true
            const params = {
                applied_org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg
            }
            RestApi.getData(trainingElearningServiceBaseUrl, `${profileInfo}`, params).then(response => {
                if (response.success) {
                    this.profileInfo = response.data
                    this.profileInfo.not_here_designation = 1
                    this.profileInfo.not_here_office = 1
                    if (response.data.trainer_status === 3 && response.data.trainer === 1) {
                        this.nonMandatory = true
                    }
                    if (!response.data.expertise_infos.length) {
                        this.profileInfo.expertise_infos = [
                            {
                                id: '',
                                expertise_id: 0,
                                expertise_name_en: '',
                                expertise_name_bn: '',
                                authenticator_name_en: '',
                                authenticator_name_bn: '',
                                attachment: null,
                                pre_attachment: []
                            }
                        ]
                    }
                    if (this.profileInfo.trainer && this.profileInfo.trainee) {
                        this.saved = true
                    } else if (this.profileInfo.first_entry === 1) {
                        this.saved = true
                    }
                    if (this.profileInfo.nid) {
                        this.invalidNid = 0
                    }
                    // this.profileInfo.image = ''
                    this.ItemShow = true
                    const typeId = this.profileInfo.address.pre_area_type_id
                    if (typeId === 1) {
                        this.CityCorpItemShow = true
                        this.PauroshobaItemShow = false
                        this.UnionItemShow = false
                    } else if (typeId === 2) {
                        this.CityCorpItemShow = false
                        this.PauroshobaItemShow = true
                        this.UnionItemShow = false
                    } else if (typeId === 3) {
                        this.CityCorpItemShow = false
                        this.PauroshobaItemShow = false
                        this.UnionItemShow = true
                    }
                    this.ItemShow1 = true
                    if (typeId === 1) {
                        this.CityCorpItemShow1 = true
                        this.PauroshobaItemShow1 = false
                        this.UnionItemShow1 = false
                    } else if (typeId === 2) {
                        this.CityCorpItemShow1 = false
                        this.PauroshobaItemShow1 = true
                        this.UnionItemShow1 = false
                    } else if (typeId === 3) {
                        this.CityCorpItemShow1 = false
                        this.PauroshobaItemShow1 = false
                        this.UnionItemShow1 = true
                    }
                    this.overlay = false
                    this.trainingData = response.otherData
                    this.profileInfo.applied_org_id = this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg
                    this.profileInfo.applied_type = this.$store.state.ExternalUserIrrigation.panelObj.trainingType
                } else {
                    this.saved = false
                    this.profileInfo.name = this.$store.state.Auth.authUser.name
                    this.profileInfo.name_bn = this.$store.state.Auth.authUser.name_bn
                    this.profileInfo.email = this.$store.state.Auth.authUser.email
                    if (this.$store.state.Auth.authUser.is_admin) {
                        this.profileInfo.mobile = this.$store.state.Auth.authUser.phone_no
                    } else {
                        this.profileInfo.mobile = this.$store.state.Auth.authUser.mobile_no
                    }
                    this.profileInfo.address.pre_area_type_id = 0
                    this.profileInfo.address.pre_division_id = this.commonProfile.division_id
                    this.profileInfo.address.pre_district_id = this.commonProfile.district_id
                    this.profileInfo.address.pre_upazilla_id = this.commonProfile.upazila_id
                    this.profileInfo.address.pre_union_id = this.commonProfile.union_id
                    this.profileInfo.address.per_area_type_id = 0
                    this.profileInfo.address.per_division_id = this.commonProfile.division_id
                    this.profileInfo.address.per_district_id = this.commonProfile.district_id
                    this.profileInfo.address.per_upazilla_id = this.commonProfile.upazila_id
                    this.profileInfo.address.per_union_id = this.commonProfile.union_id
                    // this.profileInfo.gender = this.commonProfile.gender
                    // if (!this.commonProfile.gender) {
                    //     this.profileInfo.gender = this.$store.state.Auth.authUser.phone_no
                    // }
                    this.$refs.form.reset()
                    this.overlay = true
                }
                this.loading = false
            })
        },
        onChangeFile (e) {
            this.profileInfo.image_file = e.target.files[0]
        },
        getAreaTypeData (typeId) {
            this.ItemShow = true
            if (typeId === 1) {
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (typeId === 2) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (typeId === 3) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        getAreaTypeData1 (typeId) {
            this.ItemShow1 = true
            if (typeId === 1) {
                this.CityCorpItemShow1 = true
                this.PauroshobaItemShow1 = false
                this.UnionItemShow1 = false
            } else if (typeId === 2) {
                this.CityCorpItemShow1 = false
                this.PauroshobaItemShow1 = true
                this.UnionItemShow1 = false
            } else if (typeId === 3) {
                this.CityCorpItemShow1 = false
                this.PauroshobaItemShow1 = false
                this.UnionItemShow1 = true
            }
        },
        async register () {
            this.loading = true
            let result = null
            var profileInfo = new FormData(document.getElementById('form'))
            Object.keys(this.profileInfo).map(key => {
                if (key === 'image_file') {
                    profileInfo.append('image', this.profileInfo.image_file)
                } else if (key === 'signature_file') {
                    profileInfo.append('signature', this.profileInfo.signature_file)
                } else if (key === 'nid_file') {
                    profileInfo.append('nid_attachment', this.profileInfo.nid_file)
                } else if (key === 'certificate_file') {
                    profileInfo.append('certificate_attachment', this.profileInfo.certificate_file)
                } else if (key === 'expertise_infos') {
                    // Handle expertise_infos array here if needed
                } else {
                    // Check if the value is not null, 'expertise_infos', empty, or 'null'
                    if (this.profileInfo[key] !== null && this.profileInfo[key] !== '' && this.profileInfo[key] !== 'null') {
                        profileInfo.append(key, this.profileInfo[key])
                    } else {
                         profileInfo.append(key, '')
                    }
                }
            })
            profileInfo.append('expertCheck', 2)
            profileInfo.append('trainer', 1)
            profileInfo.append('address', JSON.stringify(this.profileInfo.address))
            profileInfo.append('professional_info', JSON.stringify(this.profileInfo.professional_info))
            profileInfo.append('expertise_infos_remove', JSON.stringify(this.expertise_infos_remove))
            result = await RestApi.postData(trainingElearningServiceBaseUrl, `${profileStore}`, profileInfo)
            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: this.$t('externalTraining.please_wait_for_admin_approval'),
                    color: '#D6E09B'
                })
                EventBus.$emit('menuUpdate')
                // this.$socket.emit('send-notification', result.notification)
                this.$router.push('/trainee-trainer-panel/dashboard')
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
                this.$refs.form.setErrors(result.errors)
            }
           this.loading = false
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)

            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }

            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazilaList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }

            return unionList
        },
        getBrriOfficeTypeList (orgId = null) {
            let finalOfficeTypeList
            let officeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0)
            if (orgId) {
                if (orgId === 8) {
                officeTypeList = officeTypeList.filter(office => office.value === 85 || office.value === 101 || office.value === 120)
                }
                finalOfficeTypeList = officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
                })
            }
            this.brriOfficeTypeList = finalOfficeTypeList
        },
        getBrriDesignationByOrg (orgId = null) {
            let finalDesList
            const desigList = this.$store.state.ExternalUserIrrigation.commonObj.masterDesignationList.filter(masterDesignationList => masterDesignationList.status === 0)
            if (desigList) {
                finalDesList = desigList.filter(item => item.org_id === orgId)
            }
            this.brriDesignationList = finalDesList
        },
        getOfficeTypeList (orgId = null) {
            let officeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0)
            if (orgId) {
                if (orgId === 8) {
                    officeTypeList = officeTypeList.filter(office => office.value === 85 || office.value === 101 || office.value === 120)
                }
                return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        },
        getParentOfficeList (officeTypeId = null) {
            const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0)
            if (officeTypeId) {
                return officeList.filter(office => office.office_type_id === officeTypeId)
            }
            return officeList
        },
        getCityCorporationList (districtId) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getPauroshobaList (upazillaId = null) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getWardListByCityCorportaion (cityCorpId) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByPauroshoba (pauroshobaId) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByUnion (unionId) {
            const objectData = this.$store.state.ExternalUserIrrigation.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getUnitList (zoneId = null) {
            const unitList = this.$store.state.agriMarketing.unitList
            if (unitList) {
                return unitList.filter(item => item.zone_id === zoneId && item.status === 1)
            }
            return unitList
        },
        getExpertise (expertiseId = null) {
            const expertiseData = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingExpertiseList.filter(item => item.status === 1)
            expertiseData.push(
                {
                    value: 999,
                    text: 'Other',
                    text_en: 'Other',
                    text_bn: 'অন্যান্য'
                }
            )
            const expertiseList = expertiseData.filter(item => item.value === expertiseId && item.status === 1)
            return expertiseList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getDesignationByOrg (orgId = null) {
            const desigList = this.$store.state.ExternalUserIrrigation.commonObj.masterDesignationList.filter(masterDesignationList => masterDesignationList.status === 0)
            if (desigList) {
                return desigList.filter(item => item.org_id === orgId)
            }
            return desigList
        },
        pdfExportDetails () {
            this.$refs.details.pdfExport()
        }
    }
}
</script>
